import React, { useState } from "react"
// import { Link } from "gatsby"
import { DepositQuery, DepositVisaFormQuery } from "../../prismic/staticQueries"
import { connect, useSelector } from "react-redux"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import {
  BreadCrumbBanner,
  SubscriptionPanel,
  DepositVisaForm,
  PrismicRichText,
} from "../../components/shared"
import { trans } from "../../constants"

const OurMission = () => {
  const language = useSelector(state => state.language)
  const {
    banner_image,
    page_heading,
    neteller_description,
    neteller_heading,
    neteller_icon,

    skrill_description,
    skrill_heading,
    skrill_icon,

    visa_description,
    visa_heading,
    visa_icon,

    cashu_heading,
    cashu_icon,
    cashu_description,

    wire_transfer_description,
    wire_transfer_heading,
    wire_transfer_icon,
    wire_transfer_banks_boxes,
    bottom_text
  } = DepositQuery(language)
  const depositVisaForm = DepositVisaFormQuery(language)
  const [showWireTransfer, setWireTransfer] = useState("none")
  const [showVisa, setVisa] = useState("none")
  const [showCashu, setCashu] = useState("none")
  return (
    <>
      <Layout>
        <BreadCrumbBanner heading={page_heading} banner_image={banner_image} />

        <section className="p-top-85 p-bottom-85 section-bg">
          <div className="timeline_area section-bg timeline_area--1">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="timeline1">
                    <div className="happening row">
                      <div className="happening__period col-md-3">
                        <div className="wrapper rounded">
                          <p className="date">
                            <img src={wire_transfer_icon.url} alt="ddd" />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="happening__desc col-md-8 px-3 mt-2">
                        <h5 className="happening_title">
                          {wire_transfer_heading[0].text}
                        </h5>
                        <p>{wire_transfer_description[0].text}</p>
                        <button
                          className="btn btn-secondary"
                          onClick={() =>
                            setWireTransfer(
                              showWireTransfer === "none" ? "block" : "none"
                            )
                          }
                        >
                          {trans("proceed", language)}
                        </button>
                        <div
                          id="WireTransferInfo"
                          style={{ display: showWireTransfer }}
                        >
                          {wire_transfer_banks_boxes.map((bank, bankInd) => (
                            <div className="p-top-20" key={"sdfsdf" + bankInd}>
                              <h4 className="text-center">
                                {bank.bank_title[0].text}
                              </h4>
                              <div className="table-responsive">
                                <table className="table table-one">
                                  <tbody>
                                    <tr>
                                      <th>Bank Name</th>
                                      <td>{bank.bank_name[0].text}</td>
                                    </tr>
                                    {/* <tr>
                                      <th>Bank Account Name</th>
                                      <td>{bank.bank_account_name[0].text}</td>
                                    </tr> */}
                                    <tr>
                                      <th>Bank Address</th>
                                      <td>{bank.bank_address[0].text}</td>
                                    </tr>
                                    <tr>
                                      <th>SWIFT Code</th>
                                      <td>{bank.bank_swift_code[0].text}</td>
                                    </tr>
                                    <tr>
                                      <th>Bank Account Number</th>
                                      <td>
                                        {bank.bank_account_number[0].text}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>IBAN Number</th>
                                      <td>{bank.bank_iban_number[0].text}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="happening row">
                      <div className="happening__period col-md-3">
                        <div className="wrapper rounded shadow-sm">
                          <p className="date">
                            <img src={visa_icon.url} alt="ddd" />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="happening__desc col-md-8 px-3 mt-2">
                        <h5 className="happening_title">
                          {visa_heading[0].text}
                        </h5>
                        <p>{visa_description[0].text}</p>
                        <button
                          className="btn btn-secondary"
                          onClick={() =>
                            setVisa(showVisa === "none" ? "block" : "none")
                          }
                        >
                          {trans("proceed", language)}
                        </button>
                        <div id="cardpayment" style={{ display: showVisa }}>
                          <center>
                            <div id="checkout">
                              <div id="checkout_wrapper " className="p-top-20">
                                {/* <iframe frameborder="0" allowtransparency="true"
                          allowpaymentrequest="true"
                          src="https://api.universepay.eu/api/checkout?button=%7B%22merchant_id%22%3A1403043%2C%22currency%22%3A%22USD%22%2C%22fields%22%3A%5B%7B%22p%22%3A0%2C%22name%22%3A%22Name%22%2C%22value%22%3A%22%22%2C%22label%22%3A%22Full%20Name%22%2C%22valid%22%3A%22required%22%7D%2C%7B%22p%22%3A0%2C%22name%22%3A%22AccountLogin%22%2C%22value%22%3A%22%22%2C%22label%22%3A%22Account%20Login%22%2C%22valid%22%3A%22required%22%7D%2C%7B%22p%22%3A0%2C%22name%22%3A%22Email%22%2C%22value%22%3A%22%22%2C%22label%22%3A%22Email%22%2C%22valid%22%3A%22required%22%7D%5D%2C%22params%22%3A%7B%7D%2C%22amount%22%3A0%7D"
                          style={{zIndex: 9999, overflowX: 'hidden', border: '0px', display: 'block', position: 'static', top: '0px', left: '0px', bottom: '0px', right: '0px', width: '100%', height: '60rem'}}>
                          </iframe> */}
                                <DepositVisaForm data={depositVisaForm} />
                              </div>
                            </div>
                          </center>
                        </div>
                      </div>
                    </div>
                    <div className="happening row">
                      <div className="happening__period col-md-3">
                        <div className="wrapper rounded shadow-sm">
                          <p className="date">
                            <img src={neteller_icon.url} alt="ddd" />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="happening__desc col-md-8 px-3 mt-2">
                        <h5 className="happening_title">
                          {neteller_heading[0].text}
                        </h5>
                        <p>{neteller_description[0].text}</p>
                        <Link
                          to="/accounts/neteller"
                          className="btn btn-secondary"
                        >
                          {trans("proceed", language)}
                        </Link>
                      </div>
                    </div>
                    <div className="happening row">
                      <div className="happening__period col-md-3">
                        <div className="wrapper rounded shadow-sm">
                          <p className="date">
                            <img src={skrill_icon.url} alt="ddd" />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="happening__desc col-md-8 px-3 mt-2">
                        <h5 className="happening_title">
                          {skrill_heading[0].text}
                        </h5>
                        <p>{skrill_description[0].text}</p>
                        <Link
                          to="/accounts/skrill"
                          className="btn btn-secondary"
                        >
                          {trans("proceed", language)}
                        </Link>
                      </div>
                    </div>
                    <div>
                      <PrismicRichText data={bottom_text} />
                    </div>
                    {/* <div className="happening row">
                      <div className="happening__period col-md-3">
                        <div className="wrapper rounded shadow-sm">
                          <p className="date">
                            <img src={cashu_icon.url} alt="ddd" />
                          </p>
                        </div>
                      </div>
                      <div className="happening__desc col-md-8 offset-md-1  px-3 mt-2">
                        <h5 className="happening_title">{cashu_heading[0].text}</h5>
                        <p>{cashu_description[0].text}</p>
                        <button
                          className="btn btn-secondary"
                          onClick={() =>
                            setCashu(showCashu === "none" ? "block" : "none")
                          }
                        >
                          {trans("proceed", language)}
                        </button>
                        <div id="cardpayment" style={{ display: showCashu }}>
                          <center>
                            <div id="checkout">
                              <div id="checkout_wrapper " className="p-top-20">
                                {/* <DepositVisaForm
                                  data={depositVisaForm}
                                  paymentType="cashu"
                                /> 
                              </div>
                            </div>
                          </center>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
